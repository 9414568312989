@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700&family=Montserrat:ital,wght@0,400;0,600;1,600&family=Red+Hat+Display:wght@400;700&display=swap');

:root {
  --color-primary: #000;
  --color-secondary: #2d78e7;
  --color-complimentary: #b8eee4;
  --color-button-bg: rgb(191, 155, 248);
  --color-button-text: #fff;
  --color-white-text: #fff;
  --color-labels: #343434;
  --color-text: #343434;
}

input:focus-visible {
  border-color: var(--color-secondary) !important;
  outline: none;
}

body,
input,
button {
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 400;
  font-variant-numeric: lining-nums;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Red Hat Display', serif;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
}
