.App {
  min-height: 100vh;

  .wrapper {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin-top: 50px;
    margin-bottom: 20px;

    @media (max-width: 1024px) {
      display: block;
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
    }
  }

  .labels {
    width: fit-content;
    .title {
      color: var(--color-primary);
      font-size: 80px;
      display: block;
      font-weight: 500;
      line-height: 1;
      letter-spacing: -3.5px;
      max-width: 400px;
      margin-bottom: 24px;
      font-weight: 700;

      @media (max-width: 1024px) {
        font-size: 60px;
      }
    }

    .description {
      color: var(--color-primary);
      font-size: 20px;
      max-width: 42ch;

      margin-bottom: 15px;
    }
  }

  .form {
    width: 500px;
    @media (max-width: 1024px) {
      width: 100%;
      box-sizing: border-box;
    }
  }

  .modal-container {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;

    .copy-success {
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      margin: 10px 20px;
      width: 500px;
      height: 80px;
      border-radius: 10px;
      display: flex;
      text-align: center;
      font-size: 24px;
      align-items: center;
      justify-content: center;
      transition: background-color 0.5s;
      background-color: #fff;
      color: #000;
      visibility: hidden;

      @media (max-width: 1024px) {
        margin: 0 20px;
      }
    }
  }
}
