nav.navbar {
  display: flex;
  .logo {
    width: 100%;
    margin: 20px 40px;

    img {
      width: 200px;
    }
  }
}
